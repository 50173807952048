import Cookies, { CookieAttributes } from "js-cookie";

import {
  STER_CC_ANALYTICS_GA,
  STER_CC_ANALYTICS_GAT,
  STER_CC_ANALYTICS_GCL_AU,
  STER_CC_ANALYTICS_GID,
  STER_CC_ANALYTICS_GTM,
  STER_CC_ANALYTICS_TAG,
} from "./cookiesConstants";

// Ster cookies are set on the main domain (i.e. .ster.nl)
const defaultSterCookieOptions: Readonly<CookieAttributes> = Object.freeze({
  path: "/",
  expires: 365,
  domain:
    window.location.hostname !== "localhost"
      ? "ster.nl"
      : window.location.hostname,
  sameSite: "Lax",
  secure: true,
});

// Analytic cookies are set on main domain 'ster.nl'
const analyticCookieOptions: Readonly<CookieAttributes> = Object.freeze({
  path: "/",
  domain:
    window.location.hostname !== "localhost"
      ? "ster.nl"
      : window.location.hostname,
  sameSite: "Lax",
  secure: true,
});

/* Cleanup Google Analytics cookies */
export const cleanupGoogleAnalyticsCookies = (): void => {
  Cookies.remove(STER_CC_ANALYTICS_GA, analyticCookieOptions);
  Cookies.remove(STER_CC_ANALYTICS_GID, analyticCookieOptions);
  Cookies.remove(STER_CC_ANALYTICS_GAT, analyticCookieOptions);
  Cookies.remove(STER_CC_ANALYTICS_TAG, analyticCookieOptions);
  Cookies.remove(STER_CC_ANALYTICS_GTM, analyticCookieOptions);
  Cookies.remove(STER_CC_ANALYTICS_GCL_AU, analyticCookieOptions);
};

/* Cleanup Analytics cookies */
export const cleanupAnalyticsCookies = (): void => {
  cleanupGoogleAnalyticsCookies();
};

export const removeAllOldCookiesOnName = (cookieName: string): void => {
  if (window.location.hostname !== "localhost") {
    Cookies.remove(cookieName, { path: "/" });
    Cookies.remove(cookieName, {
      path: "/",
      domain: window.location.hostname,
    });
  }
};

export const setCookie = (
  cookieName: string,
  value: string | boolean
): void => {
  Cookies.set(cookieName, value as unknown as never, defaultSterCookieOptions);
};

/* Activate the script-tags for real! */
export const activateTags = (
  scriptTags: NodeListOf<HTMLScriptElement>
): void => {
  for (let i = 0; i < scriptTags.length; i += 1) {
    const elt = scriptTags[i];
    if (elt.dataset && (elt.dataset.stercc || elt.dataset.sterccstats)) {
      const clone = document.createElement("script");
      clone.type = "text/javascript";
      clone.text = elt.text;

      if (elt.parentNode) {
        elt.parentNode.insertBefore(clone, elt);
        elt.parentNode.removeChild(elt);
      }
    }
  }
};

/* Activate the script-tags */
export const activate = (useStatistics: boolean): void => {
  const scriptSrcTags = document.querySelectorAll(
    "script[data-stercc][type*=plain][src]"
  ) as NodeListOf<HTMLScriptElement>;
  const scriptTxtTags = document.querySelectorAll(
    "script[data-stercc][type*=plain]:not([src])"
  ) as NodeListOf<HTMLScriptElement>;

  // activate external scripts
  activateTags(scriptSrcTags);

  if (useStatistics) {
    const scriptSrcStatisticsTags = document.querySelectorAll(
      "script[data-sterccstats][type*=plain][src]"
    ) as NodeListOf<HTMLScriptElement>;
    activateTags(scriptSrcStatisticsTags);

    const scriptTxtStatisticsTags = document.querySelectorAll(
      "script[data-sterccstats][type*=plain]:not([src])"
    ) as NodeListOf<HTMLScriptElement>;
    window.setTimeout(() => {
      activateTags(scriptTxtStatisticsTags);
    }, 256);
  } else {
    window.setTimeout(() => {
      cleanupAnalyticsCookies();
    }, 256);
  }

  // After timeout, activate inline scripts
  window.setTimeout(() => {
    activateTags(scriptTxtTags);
  }, 256);
};
