export const STER_COOKIE_CONSENT = "ster_cc";
export const STER_CC_CLICKS = "ster_cc_clicks";
export const STER_CC_EXPLICITLY = "ster_cc_explicitly";
export const STER_CC_GIVEN = "ster_cc_given";
export const STER_CC_SAVEREADY = "ster_cc_saveready";
export const STER_CC_VERSION = "ster_cc_version";
export const STER_CC_USE_ANALYTICS = "ster_cc_use_analytics";

export const STER_GOOGLE_ANALYTICS_TRACKINGID = "UA-130099776-1";

export const STER_CC_ANALYTICS_GA = "_ga";
export const STER_CC_ANALYTICS_GID = "_gid";
export const STER_CC_ANALYTICS_GAT = `_gat_${STER_GOOGLE_ANALYTICS_TRACKINGID}`;
export const STER_CC_ANALYTICS_TAG = `_gat_gtag_${STER_GOOGLE_ANALYTICS_TRACKINGID}`;
export const STER_CC_ANALYTICS_GTM = `_dc_gtm_${STER_GOOGLE_ANALYTICS_TRACKINGID}`;
export const STER_CC_ANALYTICS_GCL_AU = `_gcl_au`;
