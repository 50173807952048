import "./cookieConsent.scss";

import Cookies from "js-cookie";
import React, { memo, useCallback, useState } from "react";

import pkg from "../package.json";
import {
  STER_CC_GIVEN,
  STER_CC_USE_ANALYTICS,
  STER_CC_VERSION,
} from "./cookiesConstants";
import { activate as activateScripts, setCookie } from "./utils";

export interface SterCookie {
  cookieVersion: string;
  latestVersion: string;
  consented: boolean;
  consent: {
    analytics: boolean;
  };
  renewConsent?: () => void;
}

export interface CookieConsentProps {
  link?: string;
}

const fireCookieConsentAcceptedEvent = (sterCookie: SterCookie) => {
  window.setTimeout(() => {
    window.dispatchEvent(
      new CustomEvent("CookieConsentOnAccepted", { detail: sterCookie })
    );
  }, 256);
};

const CookieConsent = ({
  link = "https://www.ster.nl/algemeen/cookiebeleid/",
}: CookieConsentProps): JSX.Element | null => {
  const [sterCookie, updateSterCookieObject] = useState<SterCookie>(() => {
    const cookieVersion = Cookies.get(STER_CC_VERSION) ?? "0.0.0";
    const latestVersion = pkg.version;

    if (cookieVersion !== latestVersion) {
      setCookie(STER_CC_GIVEN, "false");
      setCookie(STER_CC_USE_ANALYTICS, "true");
    }

    setCookie(STER_CC_VERSION, latestVersion);

    const hasConsented = Cookies.get(STER_CC_GIVEN) === "true";
    const useAnalytics = Cookies.get(STER_CC_USE_ANALYTICS) === "true";

    const sterCookieObject: SterCookie = {
      cookieVersion,
      latestVersion,
      consented: hasConsented,
      consent: {
        analytics: useAnalytics,
      },
      renewConsent: () => {
        setCookie(STER_CC_GIVEN, "false");
      },
    };
    window.stercookie = sterCookieObject;

    if (sterCookieObject.consented) {
      activateScripts(sterCookieObject.consent.analytics); // for activating 'text/plain' script-tags in app
    }

    return sterCookieObject;
  });

  const handleUseAnalyticCookiesChange = useCallback(
    (event) => {
      const {
        target: { checked },
      } = event;
      setCookie(STER_CC_USE_ANALYTICS, checked);

      const updatedSterCookie = {
        ...sterCookie,
        consent: { analytics: checked },
      };
      updateSterCookieObject(updatedSterCookie);
      window.stercookie = updatedSterCookie;
    },
    [sterCookie]
  );

  const handleUserConsentAcceptance = useCallback(() => {
    const isConsentGiven = true;
    setCookie(STER_CC_GIVEN, isConsentGiven);

    const updatedSterCookie = {
      ...sterCookie,
      consented: isConsentGiven,
    };

    window.stercookie = updatedSterCookie;
    updateSterCookieObject(updatedSterCookie);

    activateScripts(updatedSterCookie.consent.analytics);
    fireCookieConsentAcceptedEvent(updatedSterCookie);
  }, [sterCookie]);

  if (sterCookie.consented) {
    return null;
  }

  return (
    <div className="cookie-consent-outer">
      <div className="cookie-consent-inner">
        <div className="cookie-consent-text">
          <h1>Deze website maakt gebruik van cookies</h1>
          <p>
            Op deze website wordt gebruik gemaakt van functionele cookies en
            analytische cookies. Functionele cookies zijn cookies die nodig zijn
            om de website goed te laten werken. Analytische cookies zijn cookies
            die nodig zijn voor het beheer van webstatistieken waarmee wij de
            gebruikerservaring op onze website kunnen verbeteren. Wil je meer
            weten over deze cookies? Lees dan ons{" "}
            <a href={link}>cookiestatement</a>.
          </p>
          <p>
            U kunt ervoor kiezen om het gebruik van analytische cookies uit te
            schakelen. Vink dan onderstaande optie uit.
          </p>
          <div className="ster--checkbox">
            <input
              id="ga_optout_checkbox"
              name="ga_optout_checkbox"
              checked={sterCookie.consent.analytics}
              onChange={handleUseAnalyticCookiesChange}
              type="checkbox"
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="ga_optout_checkbox">Analytische cookies</label>
          </div>
        </div>
        <div className="cookie-consent-buttons">
          <button
            type="button"
            onClick={handleUserConsentAcceptance}
            name="cookie-consent-close"
            className="ster--ster-button"
          >
            Akkoord en doorgaan
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(CookieConsent);
